<template>
  <div class="diagnostic-report">
    <div class="screen clearfix">
      <div class="item">
        <span>企业名称</span>
        <div class="box">
          <el-input v-model="screen.company" placeholder="输入企业名称" style="width: 240px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>评测等级</span>
        <div class="box">
          <el-select v-model="screen.level" :popper-append-to-body="false" placeholder="全部" style="width: 120px">
            <el-option v-for="item in dictData.level" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <span>问诊时间</span>
        <div class="box">
          <el-date-picker
              v-model="whatTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd"
              style="width: 220px"
              @change="handleChangeWhatTime">
          </el-date-picker>
        </div>
      </div>
      <div class="item">
        <span>报告生成时间</span>
        <div class="box">
          <el-date-picker
              v-model="reportTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd"
              style="width: 220px"
              @change="handleChangeReportTime">
          </el-date-picker>
        </div>
      </div>
      <div class="item">
        <span>诊断人</span>
        <div class="box">
          <el-select v-model="screen.admin_id" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 140px">
            <el-option v-for="item in dictData.diagnosedByOptions" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
      <span class="button inquire" @click="search">查询</span>
      <span class="button" @click="resetQuery">重置</span>
    </div>
    <el-table
        ref="multipleTable"
        :data="tableData.data"
        :border="true"
        v-loading="tableData.loading"
        style="width: 100%"
        @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="60" align="center"></el-table-column>
      <el-table-column prop="company" label="企业名称" min-width="100%"></el-table-column>
      <el-table-column prop="company_no" label="企业编号"></el-table-column>
      <el-table-column label="报告状态" align="center">
        <template slot-scope="scope">
          <span :class="[scope.row.is_status === 1 ? 'color-blue' : '']"> {{
              scope.row.is_status == 1 ? "完整版" : '基础版'
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="评测得分" align="center">
        <template slot-scope="scope">
          <span class="color-red">{{ scope.row.diagnose_score }}</span>
        </template>
      </el-table-column>
      <el-table-column label="评测等级" align="center">
        <template slot-scope="scope">
          <span class="color-blue">{{ scope.row.diagnose_level }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="diagnose_start_time" label="问诊时间"></el-table-column>
      <el-table-column prop="diagnose_end_time" label="报告生成时间"></el-table-column>
      <el-table-column prop="full_name" label="诊断人"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <a href="JavaScript:;" @click="skip(scope)"><span class="color-blue"
                                                            style="margin-right: 20px">详情</span></a>
          <span style="cursor: pointer" v-if="scope.row.is_status == '0'" @click="editList(scope)" class="color-blue">编辑报告</span>
          <span style="cursor: pointer" v-else class="color-blue" @click="downloadPdf(scope)">下载PDF报告</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate">
      <div class="handle">
        <div class="all-select">
          <el-checkbox :indeterminate="tableData.isIndeterminate" v-model="tableData.allSelect"
                       @change="toggleSelection()"></el-checkbox>
        </div>
        <div class="button" @click="toggleSelection()">全选</div>
<!--        <div class="button">下载PDF报告</div>-->
      </div>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="screen.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="screen.pageSize"
          layout="prev, pager, next, sizes"
          :total="tableData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {timeFormat} from "@/utils/index.js"
import {diagnosesOption} from "@/request/oneEnterOneFileApi";

const defaultQuery = {
  page: 1, // 请求页码
  pageSize: 10, // 每页显示条数
  company: '',
  level: '',
  consultation_start_time: null,
  consultation_end_time: null,
  report_start_time: null,
  report_end_time: null,
  admin_id: null
}

export default {
  name: 'DiagnosticReport',
  data() {
    return {
      dictData: {
        level: [
          {id: '1', name: '无等级'},
          {id: '一级', name: '一级'},
          {id: '二级', name: '二级'},
          {id: '三级', name: '三级'},
          {id: '四级', name: '四级'},
          {id: '0', name: '全部'}
        ],//等级筛选
        diagnosedByOptions: [] // 诊断人
      },
      screen: {
        ...defaultQuery
      }, // 筛选条件
      tableData: {
        data: [], // 表格列表数据
        isIndeterminate: false, // 表格是否有选中但未全选
        allSelect: false, // 表格全选
        multipleSelection: [], // 表格选中列表
        currentPage: 1, // 表格当前页码
        total: 0, // 总条数
        loading: false, // 表格渲染loading
        ids: [], // 表格选中列表id值
      }, // 表格数据
      whatTime: [],//问诊时间
      reportTime: [],//报告时间
    }
  },
  mounted() {
    // this.initData();
    this.getDataList()

    // 获取诊断人选项
    this.$api.diagnosesOption().then(res => {
      const data = res.data.map(item => {
        return {
          id: item.id,
          name: item.full_name
        }
      })

      this.$set(this.dictData, 'diagnosedByOptions', data)
    })
  },
  methods: {
    // initData() {
    //   this.tableLoading = true;
    //   let data = {
    //     company: this.companyText,
    //     level: this.selectLevel,
    //     consultation_start_time: this.whatTime.length > 0 ? timeFormat(this.whatTime[0], 'yyyy-mm-dd') : '',
    //     consultation_end_time: this.whatTime.length > 0 ? timeFormat(this.whatTime[1], 'yyyy-mm-dd') : '',
    //     report_start_time: this.reportTime.length > 0 ? timeFormat(this.reportTime[0], 'yyyy-mm-dd') : '',
    //     report_end_time: this.reportTime.length > 0 ? timeFormat(this.reportTime[1], 'yyyy-mm-dd') : '',
    //     admin_id: '',
    //     page: this.currentPage,
    //     limit: 10
    //   }
    //   this.$api.companyDiagnose(data).then(res => {
    //     this.tableLoading = false;
    //     this.tableData = res.data.data;
    //     this.tableData.forEach((value, index) => {
    //       return value.diagnose_start_time = timeFormat(value.diagnose_start_time, 'yyyy-mm-dd hh:MM:ss');
    //     })
    //   }).catch(err => {
    //     this.tableLoading = false;
    //   })
    // },
    editList(scorp) {
      this.$router.push({path: '/editList', query: {company_id: scorp.row.id}})
    },
    downloadPdf(scorp) {
      const routeData = this.$router.resolve({ path: '/pdfReport', query: {id: scorp.row.company_id, download: true}})
      window.open(routeData.href, '_blank')
    },
    handleSelectionChange(val) {
      this.tableData.multipleSelection = val;
      this.tableData.ids = val.map(item => item.id);
      this.tableData.isIndeterminate = val.length > 0 && val.length < this.tableData.data.length;
      this.tableData.allSelect = val.length === this.tableData.data.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    // 单个星标点击
    handleStarChange(index) {
      this.tableData[index].star = !this.tableData[index].star;
    },
    handleSizeChange(val) {
      this.screen.pageSize = val;
      this.getDataList();
    }, // 分页pageSize改变
    handleCurrentChange(val) {
      this.screen.page = val;
      this.getDataList();
    }, // 分页currentPage改变
    /**
     * 查询
     */
    search() {
      this.getDataList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.screen = {
        ...defaultQuery
      }
      this.whatTime = []
      this.reportTime = []
      this.search();
    },
    /**
     * 获取表格列表数据
     */
    getDataList() {
      this.tableData.loading = true;
      this.$api.companyDiagnose(this.screen).then(res => {
        const data =  res.data.data.map(item => {
          return {
            ...item,
            diagnose_start_time: timeFormat(item.diagnose_start_time, 'yyyy-mm-dd hh:MM:ss')
          }
        })
        this.$set(this.tableData, 'data', data);
        this.$set(this.tableData, 'total', res.data.count);
        console.log(res,1124)
      }).finally(() => {
        this.tableData.loading = false;
      })
    },
    handleChangeWhatTime(value) { //选择时间之后获取网格-
      this.screen.consultation_start_time = null;
      this.screen.consultation_end_time = null;
      if (value) {
        this.screen.consultation_end_time = value[1];
        this.screen.consultation_start_time = value[0];
      }
    },
    handleChangeReportTime(value) { //选择时间之后获取网格-
      this.screen.report_end_time = null;
      this.screen.report_start_time = null;
      if (value) {
        this.screen.report_end_time = value[1];
        this.screen.report_start_time = value[0];
      }
    },
    skip(scope) { //跳转
      let id = scope.row.company_id
      let statu = scope.row.is_status
      this.$router.push({path: '/diagnosticDetail', query: {id: id, is_status: statu}})
    }
  }
}
</script>

<style lang="scss" scoped>
.diagnostic-report {
  margin: 18px;
  padding: 30px 20px;
  background: #FFFFFF;

  .screen {
    padding-bottom: 10px;

    .item {
      float: left;
      margin-bottom: 10px;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }

      span {
        float: left;
        margin-right: 12px;
        font-size: 12px;
        color: #21333F;
        line-height: 34px;
      }

      .box {
        float: left;
      }
    }

    .button {
      float: left;
      margin-left: 10px;
      width: 70px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #EEF1F3;
      border-radius: 4px;
      color: #6C7880;
      cursor: pointer;
    }

    .inquire {
      color: #FFFFFF;
      background: #3984F5;
    }
  }

  .el-table {
    .color-red {
      color: #FD5451;
    }

    .color-green {
      color: #04CD83;
    }

    .color-blue {
      color: #3984F5;
    }
  }

  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .handle {
      display: flex;

      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }

      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;

        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
}

// 输入框样式修改
::v-deep .el-input {
  font-size: 12px;

  .el-input__inner {
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }
}

// 下拉框样式修改
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: #CEDAE0;
  }

  .el-input__icon {
    font-size: 12px;
    line-height: 34px;
  }
}

// 下拉悬浮框样式修改
::v-deep .el-select-dropdown {
  border-color: #CEDAE0;
  box-shadow: 0 0 12px rgba(36, 45, 51, 0.15);

  .el-select-dropdown__item {
    font-size: 12px;
    color: #242D33;
  }

  .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .el-select-dropdown__item:hover {
    background-color: #F6F7F9;
  }

  .el-select-dropdown__item.selected {
    color: #3984F5;
    font-weight: 400;
    background-color: #F6F7F9;
  }

  .el-select-dropdown__empty {
    font-size: 12px;
  }
}

// 日期样式修改
::v-deep .el-date-editor {
  padding: 0 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #CEDAE0;
  color: #242D33;

  .el-range-input {
    width: 38%;
    font-size: 12px;
    color: #242D33;
  }

  .el-range-separator {
    width: 6%;
    font-size: 12px;
  }

  .el-range__close-icon {
    margin-right: -5px;
    width: 18px;
  }
}

// 表格样式修改
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;

  &::before,
  &::after {
    background-color: #DCE5EC;
  }

  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }

  .cell {
    line-height: 24px;
  }

  thead {
    color: #242D33;
  }

  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }

  th {
    font-weight: 400;
  }

  .el-table-column--selection .cell {
    padding-right: 10px;
  }

  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }

  td.el-table__cell {
    padding: 13px 0;
  }
}

// 分页样式修改
::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;

  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }

  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }

  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }

  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }

  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input {
    margin-right: 0;

    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;

      &:hover {
        border-color: #3984F5;
      }
    }
  }

  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }
}
</style>
